import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/imodium-duo"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690 active-trail">
                      <a
                        href="/imodium-produkte/"
                        title
                        className="active-trail"
                      >
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-menu-produkte"
                  className="block block-menu"
                >
                  <h2>Produkte</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first last expanded active-trail">
                        <a
                          href="/imodium-produkte/"
                          title
                          className="active-trail"
                        >
                          Produktübersicht
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/imodium-lingual/" title>
                              IMODIUM<sup>®</sup> lingual
                            </a>
                          </li>
                          <li className="last leaf active-trail">
                            <a
                              href="/imodium-duo/"
                              title
                              className="active-trail active"
                            >
                              IMODIUM<sup>®</sup> duo
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <h1>
                              IMODIUM® duo
                              <br />8 Tabletten
                            </h1>
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="product_detail">
                      <div className="packaging">
                        <div className="left">
                          <div className="field field-name-field-packaging-img field-type-image field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <img
                                  typeof="foaf:Image"
                                  src="/sites/default/files/kapseln_giant_0-2016_6.jpg"
                                  width="322"
                                  height="255"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="right">
                          <div className="field field-name-field-packaging-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <h2>
                                  &nbsp;Das Mittel gegen
                                  Durchfallerkrankungen
                                </h2>
                                <ul>
                                  <li>
                                    Zweifache Wirkung – bekämpft Durchfall und
                                    unangenehme, gasbedingte Bauchbeschwerden
                                    wie Krämpfe und Blähungen.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <a
                            className="packaging_file"
                            href="http://www.swissmedicinfo.ch/Accept.aspx?ReturnUrl=%2f"
                            target="_blank"
                          >
                            Packungsbeilage
                          </a>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span style={{ top: "43.5px" }}>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Gebrauch
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            IMODIUM<sup>®</sup> duo Tabletten werden zur Behandlung von
                            akutem Durchfall verwendet und enthalten als
                            zusätzlichen Wirkstoff Simeticon, um die damit
                            einhergehenden Begleiterscheinungen aufgrund von
                            Gasansammlung im Magen-Darm-Bereich, wie Blähungen
                            und Krämpfe zu lindern.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <p>
                                  IMODIUM® duo Tabletten gibt es als
                                  Packung mit 8 oder 12 Tabletten.
                                </p>
                                <h3>
                                  IMODIUM® duo
                                  <br />8 Tabletten
                                </h3>
                                <p>&nbsp;</p>
                                <h3>
                                  <img
                                    alt
                                    src="/sites/default/files/wirkstoff.jpg"
                                    style={{
                                      width: "26px",
                                      height: "26px"
                                    }}
                                  />{" "}
                                  Wirkstoff:
                                </h3>
                                <p>
                                  Loperamidhydrochlorid und Simeticon
                                  <br />
                                  &nbsp;
                                </p>
                                <h3>
                                  <img
                                    alt
                                    src="/sites/default/files/anwendungsgebiet.jpg"
                                    style={{
                                      width: "26px",
                                      height: "26px"
                                    }}
                                  />{" "}
                                  Anwendungsgebiet:
                                </h3>
                                <p>
                                  IMODIUM® duo ist ein gut wirksames
                                  Mittel gegen plötzlichen (akuten) Durchfall
                                  und die damit einhergehenden
                                  Begleiterscheinungen aufgrund von
                                  Gasansammlung im Magen-Darm-Bereich, wie
                                  Blähungen und Krämpfe bei Jugendlichen ab 12
                                  Jahren und Erwachsenen. Es enthält die beiden
                                  Wirkstoffe Loperamid und Simethicon.
                                  <br />
                                  Loperamid hemmt die Darmbewegung durch eine
                                  direkte Wirkung auf die Darmmuskulatur;
                                  dadurch wird der Stuhl dicker und die Anzahl
                                  der Stuhlgänge vermindert. Simethicon bewirkt
                                  den Zerfall des in der Regel bei Blähungen im
                                  Magen-Darm-Trakt befindlichen feinblasigen
                                  Schaums. Freiwerdende Gase können dann auf
                                  natürlichem Weg entweichen.&nbsp;Die Wirkung
                                  kann nach etwa zwei Stunden eintreten.
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="right">
                          <div className="field field-name-field-sidebar-text field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <ul>
                                  <li>
                                    <em>
                                      <strong>
                                        Dosierung Erwachsene ab 18 Jahren:{" "}
                                      </strong>
                                    </em>
                                    <br />
                                    Die Anfangsdosis beträgt 2
                                    Tabletten,&nbsp;danach nach jedem weiteren
                                    flüssigen Stuhl 1 Tablette einnehmen. Die
                                    Tageshöchstdosis beträgt 4 Tabletten. Die
                                    maximale Behandlungsdauer mit IMODIUM® duo
                                    darf 2 Tage nicht überschreiten.
                                  </li>
                                  <li>
                                    <em>
                                      <strong>
                                        Dosierung Jugendliche ab&nbsp;12 Jahren:
                                      </strong>
                                    </em>
                                    <br />
                                    Die Anfangsdosis beträgt 1 Tablette, danach
                                    nach jedem weiteren flüssigen Stuhl 1
                                    Tablette einnehmen. Die Tageshöchstdosis
                                    beträgt 4 Tabletten. Die maximale
                                    Behandlungsdauer mit IMODIUM® duo darf 2
                                    Tage nicht überschreiten.
                                    <br />
                                    <strong>
                                      Kinder unter 12 Jahren sollten nicht mit
                                      IMODIUM® duo behandelt werden.
                                    </strong>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
